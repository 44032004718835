import * as React from 'react';
import PropTypes from 'prop-types';

import GuysAtComputers from '../images/guys-at-computers';

const Hero = ({ bgcolor }) => (
  <div className={`relative overflow-hidden ${bgcolor}`}>
    <div className="max-w-screen-xl mx-auto">
      <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
        <svg
          className="absolute inset-y-0 right-0 hidden w-48 h-full text-white transform translate-x-1/2 lg:block"
          fill="currentColor"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon points="50,0 100,0 50,100 0,100" />
        </svg>
        <main className="max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8 ">
          <div className="pt-4 sm:text-center lg:text-left">
            <h2 className="text-4xl font-extrabold leading-10 tracking-tight text-gray-900 mt-14 sm:text-5xl sm:leading-none md:text-6xl">
              Manage your <span className="text-teal-600">entire business</span>{' '}
              with this software package
            </h2>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
              Finally, one-stop software any business can afford &mdash; the
              Odoo software suite &mdash; and we can get you up and running
              quickly!
            </p>
            <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <a
                  href="/get-a-demo/"
                  className="flex items-center justify-center w-full px-8 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-teal-600 border border-transparent rounded-md hover:bg-teal-500 focus:outline-none focus:border-teal-700 focus:ring-teal md:py-4 md:text-lg md:px-10"
                >
                  Try Odoo
                </a>
              </div>
              <div className="mt-3 sm:mt-0 sm:ml-3">
                <a
                  href="/what-we-do/"
                  className="flex items-center justify-center w-full px-8 py-3 text-base font-medium leading-6 text-teal-700 transition duration-150 ease-in-out bg-teal-100 border border-transparent rounded-md hover:text-teal-600 hover:bg-teal-50 focus:outline-none focus:ring-teal focus:border-teal-300 md:py-4 md:text-lg md:px-10"
                >
                  Pricing
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <GuysAtComputers className="object-cover w-full h-56 sm:h-72 md:h-96 lg:w-full lg:h-full" />
      <img
        alt=""
        className="absolute bottom-0 right-0 w-32 mb-1 mr-1"
        src="/img/logos/odoo-ready-badge.png"
      />
    </div>
  </div>
);

Hero.propTypes = {
  bgcolor: PropTypes.string,
};

Hero.defaultProps = {
  bgcolor: 'bg-white',
};

export default Hero;
